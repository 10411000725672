import {
  Box,
  FormControlLabel,
  Paper,
  Typography,
  Checkbox,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import LoadingButtonView from "../../Component/Button/LoaderButton";
import TextFieldView from "../../Component/TextField/TextFieldView";
import { UserContext } from "../../Context/AuthContext";
import { API_LOGIN } from "../../Apis/auth_api";
import { ToastShow } from "../../Component/Toast/Toast";

function LoginAuth() {
  const [email, setEmail] = useState("");
  const [isRemember, setIsRemember] = useState(false);
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState("");
  const { state } = useLocation();
  const navigation = useNavigate();
  const auth = UserContext();

  const handleLogin = async () => {
  
    setLoading(true);
   
    const data = await API_LOGIN({ email, password });
    setLoading(false);
    if (data && data.s === 1) {
      auth.login(data, isRemember);
      navigation(state ?? "/dashboard");
      ToastShow("Success");
    }else {
     
      ToastShow(data ? data.m : "Opps! something went wrong. Please try again.");
    }
  };

  useEffect(() => {
    const data = localStorage.getItem("user");

    if (data) {
      // console.log(JSON.parse(data));
      auth.login(JSON.parse(data));
      if (auth.isLogin) {
        auth.logout();
        // console.log(JSON.parse(data));
        navigation("/", { replace: true });
      } else {
        // console.log(JSON.parse(data));
        navigation(state ? state.path : "/dashboard", { replace: true });
      }
    }
  }, []);

  const handleEmail = (v) => {
    setEmail(v);
  };

  const handlePassword = (v) => {
    setPassword(v);
  };

  return (
    <Box
      sx={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Paper
        elevation={0}
        sx={{
          width: "350px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          p: 2.5,
          border: "solid",
          borderRadius: "10px",
          borderColor: "background.paper",
          borderWidth: "0.5px",
        }}
      >
        <Box
        sx={{
          borderRadius:"50%",
          p:1,
          backgroundColor:"primary.main"
        }}
        >
        <img src="android-chrome-512x512.png" alt="logo" style={{ height: "60px" }} />
        </Box>
        <Typography variant="h4" sx={{ mt: 1.3, mb: 0.3, textAlign: "center" }}>
          Welcome Back
        </Typography>
        <Typography variant="body2" sx={{ textAlign: "center" }}>
          Glad to see you here again!
        </Typography>

        {/* TEXTFIELD */}
        <TextFieldView
          label="Email"
          value={email}
          onchange={handleEmail}
          type="email"
          sx={{ mt: 5, mb: 3, width: "100%" }}
        />
        <TextFieldView
          label="Password"
          value={password}
          onchange={handlePassword}
          type="password"
          sx={{ mb: 1, width: "100%" }}
        />

        {/* REMBER & FORGOT PASSWORD */}
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <FormControlLabel
            labelPlacement="end"
            control={
              <Checkbox
                onChange={(e) => {
                  setIsRemember(e.target.checked);
                }}
              />
            }
            label={
              <Typography sx={{ fontSize: "15px" }} variant="body">
                Remember me
              </Typography>
            }
          />

          
        </Box>

        {/* BUTTON */}
        <LoadingButtonView
          loading={loading}
          lable="SignIn"
          onClick={()=>handleLogin()}
          variant="contained"
          sx={{ width: "100%", mt: 3, height: "40px" }}
        />
      </Paper>
    </Box>
  );
}

export default LoginAuth;
