import {Dashboard, Equalizer, History, Landscape, People} from '@mui/icons-material';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
export const MenuItems = [
  {
    id: "1",
    path: "/dashboard",
    isExpand: false,
    icon: <Dashboard color="#9bc2a7" />,
    title: "Dashboard",
  },
  {
    id: "2",
    path: "/users",
    isExpand: false,
    icon: <People color="#9bc2a7" />,
    title: "Users",
  },
  {
    id: "3",
    path: "/creator",
    isExpand: false,
    icon: <BorderColorIcon color="#9bc2a7" />,
    title: "Creator",
  },
  {
    id: "4",
    path: "/cover-collection",
    isExpand: false,
    icon: <LibraryBooksIcon color="#9bc2a7" />,
    title: "Cover Collection",
  },
  {
    id: "5",
    path: "/skin-type",
    isExpand: false,
    icon: <Equalizer color="#9bc2a7" />,
    title: "Skin Type",
  },
  {
    id: "6",
    path: "/skin-concern",
    isExpand: false,
    icon: <Landscape color="#9bc2a7" />,
    title: "Skin Concern",
  },
  {
    id: "7",
    path: "/version",
    isExpand: false,
    icon: <History color="#9bc2a7" />,
    title: "Version",
  },
];
