import React, { createContext, useContext, useState } from "react";
import tokens from "../Apis/config";

const AuthProvider = createContext();
function AuthContext({ children }) {
  const [user, setUser] = useState({ s: 0, m: "", r: null });

  const login = (data, isRemember) => {
    setUser(data);
    // console.log("apikey", JSON.stringify(data.r));
  
    // console.log("apikey", JSON.stringify(tokens));
    // console.log("data", data);
    if (data) {
      try {
        tokens.apikey = data.r.apikey;
        tokens.token = data.r.token;
      } catch (e) {}
    }
    if (isRemember) {
      localStorage.setItem("user", JSON.stringify(data));
    }
  };

  const logout = (data) => {
    localStorage.clear();
    setUser(data);
  };
  
  return (
    <AuthProvider.Provider
      value={{ u: user.r, login, logout, isLogin: (user && user.r &&  user.r.apikey && user.r.token) ? 1 :0 }}
    >
      {children}
    </AuthProvider.Provider>
  );
}

export default AuthContext;

export const UserContext = () => {
  const auth = useContext(AuthProvider);
  return auth;
};



