// ===========================|| DASHBOARD - TOTAL GROWTH BAR CHART ||=========================== //

const chartData = (series) => {
    return {
        options: {
            plotOptions: {
                bar: {
                    distributed: true
                }
              },
            chart: {
                id: 'Glowy',
                type: "bar",
            },
            xaxis: {
                categories: ['Creator', 'User', 'Public Routine', 'Private Routine']
            },
            responsive: [
                {
                    breakpoint: 480,
                    options: {
                        legend: {
                            position: 'bottom',
                            offsetX: -10,
                            offsetY: 0
                        }
                    }
                }
            ],
        },
        series: [
            {
                name: '',
                data: series
            }
        ],
        
    }
};
export default chartData;
