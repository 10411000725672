import { API_URL, GETAPI, POSTAPI } from "./config";

export const GET_VERSION = async (type) => {
    const res = await GETAPI(API_URL.getVersion,"&type="+type);
    return res;
  };

export const UPDATE_VERSION = async (vers,type) => {
    const formData = new FormData();
    formData.append("vers",vers);
    formData.append("type",type);
    const res = await POSTAPI(API_URL.addUpdateVersion,formData);
    return res;
};
  