import React from "react";
import { Box, TextField } from "@mui/material";
import { useId } from "react";

function TextFieldView({
 
  onchange,
  label,
  value,
  type = "text",
  variant = "outlined",
  sx = {},
  required = false
}) {
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        ...(sx)
      }}
    >
      <TextField
       fullWidth 
        required={required}
        size="small"
        sx={{ ...sx }}
        label={label}
        placeholder={label}
        type={type}
        value={value}
        onChange={(e) => {
          onchange(e.target.value);
        }}
        variant={variant}
      />
    </Box>
  );
}

export default TextFieldView;
