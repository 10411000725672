import {
  Avatar,
  Box,
  ClickAwayListener,
  Divider,
  Fade,
  IconButton,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Popper,
  Typography,
  useTheme,
} from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import { Stack } from "@mui/system";
import React from "react";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import Brightness7Icon from "@mui/icons-material/Brightness7";
import { motion } from "framer-motion";
import OrderMenuButton from "../../Component/GroupButton/OrderMenuButton";
import { RefreshOutlined } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../Context/AuthContext";

function MainView({ children, title, changeMode, drawerValue }) {
  return (
    <Box
      sx={{
        flex: "1",
        display: "flex",
        flexDirection: "column",
        ...(!drawerValue && { ml: 10.05 }),
        
      }}
    >
      {/* APPBAR */}
      <AppBar title={title} changeMode={changeMode} />

      {/* CHILDREN */}
      <Box
        sx={{
          flex: 1,
          p: "28px",
        }}
      >
        {children}
      </Box>
    </Box>
  );
}

export default MainView;

function AppBar({ title, changeMode }) {
  const navigator = useNavigate(); 
  const user = UserContext();
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen((previousOpen) => !previousOpen);
  };

  const canBeOpen = open && Boolean(anchorEl);
  const id = canBeOpen ? "transition-popper" : undefined;

  const handleClose = (event) => {
    if (anchorEl.current && anchorEl.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const theme = useTheme();

  const handleLogout = ()=>{
    user.logout({ s: 0, m: "", r: null });
    navigator("/",{replace:true});
  }

  return (
    <Paper
      elevation={0}
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        borderLeft: "dotted 2px",
        borderLeftColor: "divider",
        borderRadius: "0px",
        width: "100%",
        p: 2,
        pt: 3.5,
        height: "80px",
      }}
    >
      <Typography variant="h6">{title}</Typography>
      {/* PROFILE VIEW */}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          position:"relative"
        }}
      >
        {/* <Box>
          <Typography fontSize={"10px"} sx={{position:"absolute", top:"-12px", fontWeight:"bold"}}>Order</Typography>
        <OrderMenuButton />
        </Box> */}

        <Box
          sx={{
            ml: 1,
            mr: 1,
          }}
          component={motion.div}
          whileHover={{
            scale: 0.9,
          }}
        >
          <IconButton
            sx={{ ml: 1 }}
            onClick={() => {
              changeMode();
            }}
            color="inherit"
          >
            {theme.palette.mode === "dark" ? (
              <Brightness7Icon />
            ) : (
              <Brightness4Icon />
            )}
          </IconButton>
        </Box>

        <Box
          sx={{
            mr: 2,
          }}
          component={motion.div}
          whileHover={{
            scale: 0.9,
          }}
        >
          <IconButton
            onClick={() => {
              window.location.reload();
            }}
            color="inherit"
          >
            <RefreshOutlined />
          </IconButton>
        </Box>

        <motion.div
          whileHover={{
            scale: 0.9,
          }}
        >
          <Avatar
            aria-describedby={id}
            type="button"
            onClick={handleClick}
            sx={{
              height: "30px",
              width: "30px",
              cursor: "pointer",
            }}
            aria-controls={open ? "menu-list-grow" : undefined}
            aria-haspopup="true"
            color="inherit"
          />
          <Popper id={id} open={open} anchorEl={anchorEl} transition>
            {({ TransitionProps }) => (
              <Fade {...TransitionProps} timeout={350}>
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <Box sx={{ p: 2 }}>
                      <Stack>
                        <Stack
                          direction="row"
                          spacing={0.5}
                          alignItems="center"
                        >
                          <Typography variant="h6">Hi,</Typography>
                          <Typography
                            component="span"
                            variant="h6"
                            sx={{ fontWeight: 400 }}
                          >
                            Admin
                          </Typography>
                        </Stack>
                        <Typography variant="subtitle2">
                          Take your action
                        </Typography>
                      </Stack>
                      <Divider sx={{ my: 1 }} />
                      <ListItemButton
                        sx={{ borderRadius: "10px" }}
                        
                        onClick={handleLogout}
                      >
                        <ListItemIcon>
                          <LogoutIcon stroke={1.5} size="1.3rem" />
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            <Typography variant="body2">Logout</Typography>
                          }
                        />
                      </ListItemButton>
                    </Box>
                  </ClickAwayListener>
                </Paper>
              </Fade>
            )}
          </Popper>
        </motion.div>
      </Box>
    </Paper>
  );
}
