import React, { useEffect, useRef, useState } from 'react';
import DataGrideView from '../../../Component/DataGrideView/DataGrideView';
import { CHANGE_STATUS_USER, DELETE_USER, GET_USERS_LIST } from '../../../Apis/users_api';
import { Avatar, Box, Button, Typography } from '@mui/material';
import { API_URL } from '../../../Apis/config';
import { ToastShow } from '../../../Component/Toast/Toast';
import ConfirmationDailog from '../../../Component/Dailog/ConfirmtionDailog';



function Creator() {

    const [rows, setRows] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isCall, setIsCall] = useState(true);
    const selectedId = useRef({});
    const [open, setOpen] = useState(false);

    const getData = async (v = 1) => {
        setIsLoading(true);

        const data = await GET_USERS_LIST(2, v ? rows.length : 0);

        if (v) {

            setRows([...rows, ...(data.r ?? [])]);
        } else {
            setRows([...(data.r ?? [])]);
        }
        setIsLoading(false);
        if ((data.r ?? []).length == 0) {
            setIsCall(false);
        }
    };


    useEffect(() => {
        getData(0);
    }, []);

    const callBack = (l) => {
        if (l >= rows.length && isCall) {
            getData();
        }
    };


    const columns = [
        {
            field: "id",
            headerName: "Sr.",
            headerAlign: "center",
            align: "center",
            width: 50,
            renderCell: (params) => {
                return params.api.getRowIndex(params.row.id) + 1;
            },
        },

        {
            field: "profile",
            headerName: "Profile",
            headerAlign: "center",
            align: "center",
            width: 150,
            renderCell: (params) => {
                return <Avatar
                    onClick={() =>{
                        if(params.value){
                            window.open(`${API_URL.profileImage}${params.value}`)
                        }
                    }}

                    sx={{
                        cursor: "pointer",
                        height: "50px",
                        width: "50px"
                    }}
                    src={`${API_URL.profileImage}${params.value}`} />
            }
        },
        {
            field: "name",
            headerName: "Name",
            headerAlign: "center",
            align: "center",
            width: 250,
        },

        {
            field: "email",
            headerName: "Email",
            headerAlign: "center",
            align: "center",
            width: 250,
        },
        {
            field: "r_count",
            headerName: "T.Routine",
            headerAlign: "center",
            align: "center",
            width: 250,
        },
        {
            field: "follower_count",
            headerName: "T.Follower",
            headerAlign: "center",
            align: "center",
            width: 250,
        },
        {
            field: "action",
            headerAlign: "center",
            align: "center",
            headerName: "Action",
            width: 155,
            renderCell: (params) => {
              return (
                <Box
                  sx={{
                    display: "flex",
                  }}
                >
                  <Button
                    size="small"
                    variant="outlined"
                    sx={{
                      borderColor: params.row.status == 1 ? "red" : "green",
                      borderTopRightRadius: "0px",
                      borderBottomRightRadius: "0px",
                      borderRightColor: "#c0c0c0",
                      color: params.row.status == 1 ? "red" : "green",
                    }}
                    onClick={() =>
                      onSubmit(params.row.id, params.row.status == 0 ? 1 : 0)
                    }
                  >
                    {params.row.status == 0 ? "Unblock" : "Block"}
                  </Button>
                  <Button
                    size="small"
                    variant="outlined"
                    sx={{
                      borderTopLeftRadius: "0px",
                      borderBottomLeftRadius: "0px",
                      borderLeftColor: "#c0c0c0",
                      color: "red",
                    }}
                    onClick={() => {
                      selectedId.current = {fId:params.row.fId,id:params.row.id};
                      setOpen(true);
                    }}
                  >
                    Delete
                  </Button>
                </Box>
              );
            },
          },
    ];

    const onSubmit = async (id, status) => {

        const res = await CHANGE_STATUS_USER(id, status );
        if (res && res.s) {
            ToastShow("Success");
            //getData(0);

            setRows(rows.map((v) => ({ ...v, status: v.id == id ? status : v.status })));
        } else {
            ToastShow("Opps! something went wrong. Please try again.");
        }
    };

    
  const onDelete = async ({fId,id}) => {
    const res = await DELETE_USER(fId,id);
    if (res && res.s) {
      ToastShow("Success");
      getData(0);
    } else {
      ToastShow("Opps! something went wrong. Please try again.");
    }
    selectedId.current = {};
    setOpen(false);
  };


    return (
        <>
            <DataGrideView
                columns={columns}
                rows={rows}
                callBack={callBack}
                isLoading={isLoading}
            />

{open && (
        <ConfirmationDailog
          handleOnClose={(v) => {
            if (v) {
              onDelete(selectedId.current);
            } else {
                selectedId.current = {};
              setOpen(false);
            }
          }}
          open={open}
          content="Are you sure, You want to delete this creator ?"
          title="Delete Creator!"
        />
      )}
        </>
    )
}

export default Creator
