import { API_URL, GETAPI, POSTAPI } from "./config";

export const ADD_UPDATE_SKIN_TYPE = async (data) => {
  const formData = new FormData();

  formData.append("title", data.title);
  formData.append("description", data.description);

  if (data.id) {
    formData.append("id", data.id);
  }

  const res = await POSTAPI(API_URL.addUpdateSkinType, formData);

  return res;
};

export const GET_SKIN_TYPE = async () => {
  const res = await GETAPI(API_URL.getSkinType);

  return res;
};

export const DELETE_SKIN_TYPE = async (data) => {
  const formData = new FormData();

  formData.append("id", data.id);

  const res = await POSTAPI(API_URL.deleteSkinType, formData);

  return res;
};


export const GET_SKIN_Concern= async () => {
  const res = await GETAPI(API_URL.getSkinConcern);

  return res;
};

export const DELETE_SKIN_Concern = async (data) => {
  const formData = new FormData();

  formData.append("id", data.id);

  const res = await POSTAPI(API_URL.deleteSkinConcern, formData);

  return res;
};


export const ADD_UPDATE_SKIN_Concern = async (data) => {
  const formData = new FormData();

  formData.append("title", data.title);
 

  if (data.id) {
    formData.append("id", data.id);
  }

  const res = await POSTAPI(API_URL.addUpdateSkinConcern, formData);

  return res;
};
