import { Fab } from "@mui/material";
import React, { useEffect, useState } from "react";
import DataGrideView from "../../../Component/DataGrideView/DataGrideView";
import AddIcon from "@mui/icons-material/Add";
import DeleteDailog from "../../../Component/Dailog/DeleteDailog";
import SplitButton from "../../../Component/Button/SplitButton";
import { DELETE_SKIN_Concern, DELETE_SKIN_TYPE, GET_SKIN_Concern, GET_SKIN_TYPE } from "../../../Apis/skin_api";
import AddSkinType from "../../../Component/Dailog/AddSkinType";
import { ToastShow } from "../../../Component/Toast/Toast";
import AddSkinConcern from "../../../Component/Dailog/AddSkinConcern";

function SkinConcern() {
  const [rows, setRows] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isCall, setIsCall] = useState(true);
  const [open, setOpen] = useState(false);
  const [openD, setOpenD] = useState(false);
  const [selectedId, setSelectedId] = useState(0);
  const [selectdRow, setSelectedRows] = React.useState(null);

  const getData = async () => {
    setIsLoading(true);

    const data = await GET_SKIN_Concern();

    if (data && data.s) {
      setRows([...(data.r.skin_concern ?? [])]);
    }
    setIsLoading(false);
    
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClickOpenD = () => {
    setOpenD(true);
  };

  const handleOnClose = async (v) => {
    setOpen(false);
    setSelectedRows(null);
    if (v) {
      getData(0);
    }
  };

  const handleOnCloseD = async (v) => {
    setOpenD(false);
    if (v) {
      const d = await DELETE_SKIN_Concern({ id: selectedId });
      setSelectedId(0);
      if (d && d.s) {
        ToastShow("Deleted Successfully");
        getData(0);
      } else {
        ToastShow("Opps! something went wrong please try again.");
      }
    }
  };

  useEffect(() => {
    getData(0);
  }, []);

  const callBack = (l) => {
    if (l >= rows.length && isCall) {
      getData();
    }
  };

  const handelDelete = (id) => {
    setSelectedId(id);
    handleClickOpenD();
  };

  const onEdit = (id) => {
    const t = rows.filter((v) => v.id == id);
    setSelectedRows(t[0]);
    setOpen(true);
  };

  const columns = [
    {
      field: "id",
      headerName: "Sr.",
      headerAlign: "center",
      align: "center",
      width: 50,
      renderCell: (params) => {
        return params.api.getRowIndex(params.row.id) + 1;
      },
    },
    {
      field: "title",
      headerName: "Title",
      headerAlign: "center",
      align: "center",
      width: 150,
    },
    {
      field: "action",
      headerName: "Action",
      headerAlign: "center",
      align: "center",
      width: 150,
      renderCell: (params) => (
        <SplitButton
          key={params.id}
          dataId={params.id}
          isDelete={1}
          onDelete={handelDelete}
          title={params.value}
          onEdit={onEdit}
        />
      ),
    },
  ];

  return (
    <>
      <DataGrideView
        columns={columns}
        rows={rows}
        // callBack={callBack}
        isLoading={isLoading}
      />
      <Fab
        onClick={handleClickOpen}
        sx={{
          position: "fixed",
          bottom: (theme) => theme.spacing(2),
          right: (theme) => theme.spacing(2),
        }}
        size="medium"
        color="primary"
        aria-label="add"
      >
        <AddIcon />
      </Fab>

      {open ? (
        <AddSkinConcern
          open={open}
          handleOnClose={handleOnClose}
          row={selectdRow}
        />
      ) : null}
      {openD ? (
        <DeleteDailog
          open={openD}
          handleOnClose={handleOnCloseD}
          title="Delete Skin concern!"
          subtitle="Are you sure you want to delete skin concern?"
        />
      ) : null}
    </>
  );
}

export default SkinConcern;
