import {
  Button,
  Dialog,
  DialogActions,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import React from "react";
import ButtonView from "../Button/ButtonView";

function ConfirmationDailog({
  open,
  handleOnClose,
  title = "Delete Banner",
  content = "Are you sure, You want to delete this banner.",
}) {
  return (
    <>
      <Dialog
        open={open}
        onClose={() => handleOnClose(0)}
        sx={{
          backdropFilter: "blur(3px)",
          bgcolor: "rgba(105, 55, 0, 0.3)",
        }}
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContentText sx={{ width: "350px", px: 3 }}>
          {content}
        </DialogContentText>
        <DialogActions>
          <Button onClick={() => handleOnClose(1)}> Yes</Button>
          <Button onClick={() => handleOnClose(0)}> No </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default ConfirmationDailog;
