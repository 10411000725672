import { Box, Button, Paper, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { GET_VERSION, UPDATE_VERSION } from "../../../Apis/version_api";
import { ToastShow } from "../../../Component/Toast/Toast";

function Version() {
  const [android, setAndroid] = useState("");
  const [ios, setIos] = useState("");

  const getData = async () => {
    const dataAndroid = await GET_VERSION("android");
    const dataIos = await GET_VERSION("ios");

    if (dataAndroid) {
      setAndroid(dataAndroid.r.vers);
    }

    if (dataIos) {
      setIos(dataIos.r.vers);
    }
  };

  const updateVersion = async(vers,type) => {
    const d = await UPDATE_VERSION(vers, type);
    if (d && d.s) {
      ToastShow("Success");
    } else {
      ToastShow("Opps! something went wrong. Please try again.");
    }
  };

  useEffect(() => {
    getData();
  }, []);
  return (
    <Paper
      sx={{
        margin: "20px",
        padding: "20px",
      }}
    >
      <Typography variant="h4" mb={2}>
        Versions
      </Typography>
      <Box display="flex" gap={10}>
        <TextField
          sx={{
            width: "300px",
          }}
          size="small"
          variant="filled"
          label="Android Version"
          value={android}
          onChange={(e) => setAndroid(e.target.value)}
          placeholder="Enter android version..."
        />
        <Button
        onClick={()=>updateVersion(android,"android")}
          sx={{
            width: "150px",
          }}
          variant="contained"
        >
          Update
        </Button>
      </Box>

      <Box display="flex" mt={3} gap={10}>
        <TextField
          sx={{
            width: "300px",
          }}
          size="small"
          variant="filled"
          label="Ios Version"
          value={ios}
          onChange={(e) => setIos(e.target.value)}
          placeholder="Enter ios version..."
        />
        <Button
        onClick={()=>updateVersion(ios,"ios")}
          sx={{
            width: "150px",
          }}
          variant="contained"
        >
          Update
        </Button>
      </Box>
    </Paper>
  );
}

export default Version;
