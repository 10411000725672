import { Box } from "@mui/material";
import React from "react";
import DrawerView from "./Drawer/DrawerView";
import MainView from "./MainView";


function Home({ children, title, changeMode }) {
  const [openD, setOpenD] = React.useState(true);
  const [drawerValue, setDrawerValue] = React.useState(true);
  const handleDrawer = (isRelative = 0, value = false) => {
    if (isRelative === 1) {
      setOpenD(value);
    } else if (isRelative === 2 && drawerValue === false) {
      setOpenD(true);
      setDrawerValue(true);
    } else {
      setOpenD((privious) => !privious);
      setDrawerValue((privious) => !privious);
    }
  };
  return (
    <Box
      sx={{
        display: "flex",

        justifyContent: "space-between",
        ...(!drawerValue && { position: "relative" }),
      }}
    >
      <DrawerView
        handleDrawer={handleDrawer}
        openD={openD}
        drawerValue={drawerValue}
      />
      <MainView title={title} changeMode={changeMode} drawerValue={drawerValue}>
        {children}
      </MainView>
    </Box>
  );
}

export default Home;
