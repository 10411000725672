import { Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import React from "react";

function LoadingButtonView({
  lable,
  onClick,
  sx = {},
  variant = "contained",
  loading = false,
}) {
  return (
    <LoadingButton
      loading={loading}
      disableElevation
      size="small"
      onClick={() => onClick()}
      sx={{ ...sx }}
      variant={variant}
    >
      <Typography sx={{ color: "black" }}>{lable}</Typography>
    </LoadingButton>
  );
}

export default LoadingButtonView;
